<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import Product                  from '../components/product.vue'
import MixNMatchTaxonomySelect  from '../components/mix_n_match_taxonomy_select.vue'
import VueAwesomeSwiper         from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)

export default Vue.component('mix-n-match-item', {
  props: {
    taxonomySlug: String,
    buttonNext: String,
    buttonPrev: String,
    per: { default: 100, type: Number },
    sort: { default: 'product_id-asc', type: String },
    mixNmatch: { default: true, type: Boolean }
  },
  data() {
    return {
      selectedTaxonomy: null,
      page: 1,
      slideIndex: 1,
      taxonomies: [],
      products: [],
      meta: [],
      errors: [],
      tmt: null,
      loading: false,
      swiperOption: {
        slidesPerView: 5,
        breakpoints: {
          480: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1200: {
            slidesPerView: 4
          }
        },
        centeredSlides: true,
        spaceBetween: 0,
        setWrapperSize: true,
        observeParents: true,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        mousewheelControl: false,
        onSlideChangeStart: (swiper) => {
          this.setSelectedSlide(swiper)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      } else {
        return ''
      }
    },
    swiper() {
      if (this.$refs && this.$refs.gySwiper)
        return this.$refs.gySwiper.swiper
    },
    hasErrors() {
      return this.errors.length > 0
    }
  },
  created() {
    if (this.buttonNext) {
      this.swiperOption.nextButton = this.buttonNext
    }

    if (this.buttonPrev) {
      this.swiperOption.prevButton = this.buttonPrev
    }
  },
  watch: {
    slideIndex: function(val, oldVal) {
      if (val != oldVal) {
        if ((val % this.per > 2 || val % this.per == 0) && this.products[val + 3] == undefined && this.products.length < this.meta.products_count) {
          clearTimeout(this.tmt)
          this.tmt = setTimeout(() => {
            console.log("Loading next page...")
            this.loadNextPage()
          }, 30)
        }
      }
    }
  },
  mounted() {
    this.load(true)
  },
  methods: {
    load(clear = false) {
      let slug = this.selectedTaxonomy ? this.selectedTaxonomy.slug : this.taxonomySlug
      this.loading = true

      this.$http.get(`${this.apiPath}/catalog/${slug}`, { params: { mix_n_match: this.mixNmatch, sort: this.sort, per: this.per, page: this.page, versions: this.stringifiedVersions, lists: this.$parent.handle } }).then(response => {
        this.taxonomies = response.body.lists[Object.keys(response.body.lists)[0]]
        this.meta       = response.body.meta

        if (clear)
          this.products = []

        for (let product of response.body.products) {
          this.products.push(product)
        }

        // Set slide to 3rd
        setTimeout(() => {
          if (clear)
            this.swiper.slideTo(2, 500, false)

          this.setSelectedSlide(this.swiper)
        }, 500)
      })

      this.loading = false
    },

    refreshProducts(taxonomy) {
      this.swiper.slideTo(0, 500, false)

      setTimeout(() => {
        this.selectedTaxonomy = taxonomy
        this.page     = 1

        this.load(true)

        this.$store.dispatch('triggerEvent', {
          type: 'gy::mix-n-match-item-products-loaded',
          message: "Τα προϊόντα έγιναν load από το API."
        })
      }, 500)
    },

    loadNextPage() {
      this.page += 1
      this.load()
    },

    setSelectedSlide(swiper) {
      this.slideIndex = swiper.realIndex + 1

      for (let product of this.products) {
        this.$set(product, 'selected', false)
      }

      this.$set(this.products[swiper.realIndex], 'selected', true)
    }

  },
  components: {
    Product,
    MixNMatchTaxonomySelect,
    VueAwesomeSwiper
  }
})
</script>

<style src="swiper/dist/css/swiper.css"></style>
