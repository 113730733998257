import Vue              from 'vue'
import VueResource      from 'vue-resource'
import { mapGetters }   from 'vuex'
import { store }        from '../lib/store'
import savedOrdersMixin from '../mixins/saved_orders_mixin'
import SavedOrder       from '../components/saved_orders/SavedOrder.vue'

Vue.use(VueResource)

export default new Vue({
  el: '#gy-saved-orders',
  components: {
    SavedOrder
  },
  mixins: [savedOrdersMixin],
  data: {
    savedOrders: []
  },
  store,
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    if (this.$el.id.length > 0) {
      this.loadSavedOrders()
    }
  }
})
