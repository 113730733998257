<template>
  <div class="card-form">
    <button
      class="gy-credit-card-close-modal"
      @click="$emit('close-modal')"
    >
      <i class="mdi mdi-window-close" />
    </button>

    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :is-card-number-masked="isCardNumberMasked"
        :random-backgrounds="randomBackgrounds"
        :background-image="backgroundImage"
        :default-card-name="defaultCardName"
      />
    </div>
    <div class="card-form__inner">
      <Secure3DIframe
        v-if="secure3DEnabled"
        :gateway="simplify"
      />
      <div v-if="!secure3DEnabled">
        <div class="card-input">
          <label
            for="cardNumber"
            class="card-input__label"
          >{{ i18n.cardNumber }}</label>
          <input
            :id="fields.cardNumber"
            type="tel"
            class="card-input__input"
            :value="formData.cardNumber"
            :maxlength="cardNumberMaxLength"
            data-card-field
            autocomplete="off"
            @input="changeNumber"
            @focus="focusCardNumber"
            @blur="blurCardNumber"
          >
          <button
            class="card-input__eye"
            :class="{ '-active' : !isCardNumberMasked }"
            title="Show/Hide card number"
            tabindex="-1"
            :disabled="formData.cardNumber === ''"
            @click="toggleMask"
          />
          <div class="card-input__error-wrapper">
            <span v-if="creditCard.errors && creditCard.errors.cardNumber"> {{ creditCard.errors.cardNumber }}</span>
          </div>
        </div>
        <div class="card-input">
          <label
            for="cardName"
            class="card-input__label"
          >{{ i18n.cardName }}</label>
          <input
            :id="fields.cardName"
            v-letter-only
            type="text"
            class="card-input__input"
            :value="formData.cardName"
            data-card-field
            autocomplete="off"
            maxlength="50"
            @input="changeName"
          >
          <div class="card-input__error-wrapper">
            <span v-if="creditCard.errors && creditCard.errors.cardName"> {{ creditCard.errors.cardName }}</span>
          </div>
        </div>
        <div class="card-form__row">
          <div class="card-form__col">
            <div class="card-form__group">
              <label
                for="cardMonth"
                class="card-input__label"
              >{{ i18n.expirationDate }}</label>
              <select
                :id="fields.cardMonth"
                v-model="formData.cardMonth"
                class="card-input__input -select"
                data-card-field
                @change="changeMonth"
              >
                <option
                  value
                  disabled
                  selected
                >
                  {{ i18n.month }}
                </option>
                <option
                  v-for="n in 12"
                  :key="n"
                  :value="n < 10 ? '0' + n : n"
                  :disabled="n < minCardMonth"
                >
                  {{ generateMonthValue(n) }}
                </option>
              </select>
              <select
                :id="fields.cardYear"
                v-model="formData.cardYear"
                class="card-input__input -select"
                data-card-field
                @change="changeYear"
              >
                <option
                  value
                  disabled
                  selected
                >
                  {{ i18n.year }}
                </option>
                <option
                  v-for="(n, $index) in 12"
                  :key="n"
                  :value="$index + minCardYear"
                >
                  {{ $index + minCardYear }}
                </option>
              </select>
            </div>
            <div class="card-input__error-wrapper">
              <span v-if="creditCard.errors && creditCard.errors.cardMonth"> {{ creditCard.errors.cardMonth }}</span>
              <span v-if="creditCard.errors && creditCard.errors.cardYear"> {{ creditCard.errors.cardYear }}</span>
            </div>
          </div>
          <div class="card-form__col -cvv">
            <div class="card-input">
              <label
                for="cardCvv"
                class="card-input__label"
              >{{ i18n.cvv }}</label>
              <input
                :id="fields.cardCvv"
                v-number-only
                type="tel"
                class="card-input__input"
                maxlength="4"
                :value="formData.cardCvv"
                data-card-field
                autocomplete="off"
                @input="changeCvv"
              >
              <div class="card-input__error-wrapper">
                <span v-if="creditCard.errors && creditCard.errors.cardCvv"> {{ creditCard.errors.cardCvv }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-form__card-declined">
          <span v-if="paymentDeclined">
            {{ i18n.message_for_declined_payment }} {{ creditCardResponse.status }}
          </span>
          <span v-if="creditCard.errors.genericError">
            {{ creditCard.errors.genericError }}
          </span>
        </div>
        <button
          v-if="paymentPending"
          class="card-form__button"
          :disabled="creditCardLoading"
          @click="pay"
        >
          <i
            v-if="!creditCardLoading"
            class="mdi mdi-shield-lock mdi-24px"
          />
          <i
            v-if="creditCardLoading"
            class="mdi mdi-loading mdi-spin mdi-24px"
          />
          {{ i18n.submit }}
        </button>

        <button
          v-if="paymentDeclined"
          class="card-form__button declined"
          @click="enablePay"
        >
          <i
            class="mdi mdi-shield-alert mdi-24px"
          />

          {{ i18n.payment_not_approved }}
        </button>

        <button
          v-if="paymentApproved"
          class="card-form__button approved"
        >
          <i
            class="mdi mdi-shield-check mdi-24px"
          />

          {{ i18n.payment_approved }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../../lib/store'
import { mapState } from 'vuex'
import Card from './Card.vue'
import Secure3DIframe from './Secure3DIframe.vue'
import NiceI18n from '../../lib/nice_i18n'

export default {
  store,
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    }
  },
  components: {
    Card,
    Secure3DIframe
  },
  props: {
    clientId: {
      type: String
    },
    type: {
      type: String
    },
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: '',
          cardNumber: '',
          cardNumberNotMask: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: ''
        }
      }
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true
    },
    defaultCardName: {
      type: String
    }
  },
  data () {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      i18n: {
        cardNumber: NiceI18n.t('pay_modal.card_form.card_number'),
        cardName: NiceI18n.t('pay_modal.card_form.card_name'),
        expirationDate: NiceI18n.t('pay_modal.card_form.expiration_date'),
        month: NiceI18n.t('pay_modal.card_form.month'),
        year: NiceI18n.t('pay_modal.card_form.year'),
        cvv: NiceI18n.t('pay_modal.card_form.cvv'),
        submit: NiceI18n.t('pay_modal.card_form.submit'),
        payment_approved: NiceI18n.t('pay_modal.card_form.payment_approved'),
        payment_not_approved: NiceI18n.t('pay_modal.card_form.payment_not_approved'),
        message_for_declined_payment: NiceI18n.t('pay_modal.card_form.message_for_declined_payment')
      }
    }
  },
  computed: {
    ...mapState([
      'creditCardLoading',
      'creditCardResponse',
      'creditCard',
      'simplify'
    ]),
    minCardMonth () {
      if (this.formData.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
    paymentPending() {
      return (this.creditCardResponse.success == undefined)
    },
    paymentApproved() {
      if (this.creditCardResponse.success == undefined)
        return false

      return this.creditCardResponse.success
    },
    paymentDeclined() {
      if (this.creditCardResponse.success == undefined)
        return false

      return !this.paymentApproved
    },
    secure3DEnabled() {
      return this.creditCardLoading && this.simplify && this.simplify.secure3DEnabled
    }
  },
  watch: {
    cardYear () {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = ''
      }
    }
  },
  mounted () {
    this.maskCardNumber()
  },
  methods: {
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    changeName (e) {
      this.formData.cardName = e.target.value
      this.$emit('input-card-name', this.formData.cardName)
    },
    changeNumber (e) {
      this.formData.cardNumber = e.target.value
      let value = this.formData.cardNumber.replace(/\D/g, '')
      // american express, 15 digits
      if ((/^3[47]\d{0,13}$/).test(value)) {
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
        this.formData.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.formData.cardNumber.substring(this.formData.cardNumber.length, this.formData.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') { this.formData.cardNumber = this.formData.cardNumber.substring(0, this.formData.cardNumber.length - 1) }
      }
      this.$emit('input-card-number', this.formData.cardNumber)
    },
    changeMonth () {
      this.$emit('input-card-month', this.formData.cardMonth)
    },
    changeYear () {
      this.$emit('input-card-year', this.formData.cardYear)
    },
    changeCvv (e) {
      this.formData.cardCvv = e.target.value
      this.$emit('input-card-cvv', this.formData.cardCvv)
    },
    pay() {
      this.$store.commit('setCreditCardLoading', true)
      this.$emit('submit-pay')
    },
    enablePay() {
      this.$store.commit('setCreditCardResponse', {})
    },
    invalidCard () {
      let number = this.formData.cardNumberNotMask.replace(/ /g, '')
      var sum = 0
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if (sum % 10 !== 0) {
        alert(NiceI18n.t('cardForm.invalidCardNumber'))
      }
    },
    blurCardNumber () {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    maskCardNumber () {
      this.formData.cardNumberNotMask = this.formData.cardNumber
      this.mainCardNumber = this.formData.cardNumber
      let arr = this.formData.cardNumber.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.formData.cardNumber = arr.join('')
    },
    unMaskCardNumber () {
      this.formData.cardNumber = this.mainCardNumber
    },
    focusCardNumber () {
      this.unMaskCardNumber()
    },
    toggleMask () {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    }
  }
}
</script>
<style lang="scss">

  .gy-credit-card-close-modal {
    position: absolute;
    right: -20px;
    top: 78px;
    width: 50px;
    font-size: 22px;
    border: none;
    color: white;
    padding: 14px;
    font-weight: bold;
    background-color: #fff;
    line-height: 1;
    border-radius: 50%;
    color: #aaa;
    z-index: 9999;

    @media screen and (max-width: 772px) {
      top: 105px;
      right: 4px;
      width: 30px;
      padding: 4px;
      font-size: 20px;
      background-color: transparent;
    }

    @media screen and (max-width: 380px) {
      right: 4px;
      top: -14px;
      background-color: #fff;
    }

    &:hover {
      color: #000;
    }
  }

</style>
