<script>
// Import Vue & VueX
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

// Import the EventBus
import { EventBus } from '../lib/event_bus.js'

// Lib
import NiceI18n   from '../lib/nice_i18n'
import niceModal  from '../mixins/nice_modal'

// Import plugins
import VModal from 'vue-js-modal'

export default Vue.component('address-item', {
  mixins: [niceModal],
  props: {
    address: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  methods: {
    /*
     * Triggers edit popup
     */
    edit() {
      EventBus.$emit("populate-form", this.address)
      this.$store.dispatch('triggerEvent', 'gy::address-form-populated')
    },

    /*
     * Deletes an address
     */
    destroy() {
      let message      = NiceI18n.t("addresses.prompt_to_delete")
      let submitButton = { title: NiceI18n.t("addresses.delete"), handler: () => { this._delete() } }
      let cancelButton = { title: NiceI18n.t("addresses.cancel_destroy"), default: true }

      this.showModal(message, [submitButton, cancelButton])
    },

    /*
     * The actual API call that destroys an address
     */
    _delete() {
      this.$http.delete(`${this.apiPath}/addresses/${this.address.id}`).then(response => {
        EventBus.$emit("reload-addresses")
        this.$modal.hide('dialog');
        this.$store.dispatch('triggerEvent', { type: 'gy::address-deleted', message: response.body.message })
      },
      (error) => {
        this.$store.dispatch('triggerEvent', { type: 'gy::address-error-deleted', message: error.body.message })
      })
        .catch((error) => {})
    }
  }
})
</script>
