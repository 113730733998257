<script>
import { mapState, mapGetters } from 'vuex'
import OptionVariantRow from './OptionVariantRow.vue'

export default {
  components: {
    OptionVariantRow
  },
  props: {
    chosenOptions: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => [],
      type: Array
    },
    lineItems: {
      default: () => [],
      type: Array
    },
    enableEdit: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      totalQuantity: 0,
      totalAmount: 0.0,
      totalSavings: 0.0
    }
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    activeOption() {
      return (this.options)[0]
    },
    sortedOptionVariants() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.optionVariants.sort((a, b) => a.title - b.title)
    },
    optionVariants() {
      let optionVariants = (this.activeOption || {}).option_variants

      return optionVariants || []
    },
    autoFillQuantities(){
      return this.lineItems.length > 0
    }
  },
  methods: {
    addToCart($event) {
      this.$parent.addToCart($event)
    },
    showOptionVariantsTable() {
      return (this.$parent.step > this.chosenOptions.length)
    },
    updateTotal() {
      let totalQuantity   = 0
      let totalDiscounted = 0.0
      let total           = 0.0

      for (let component of this.$refs.optionVariantRow) {
        let quantity = parseInt(component.quantity)

        if (quantity > 0) {
          totalQuantity   += quantity
          totalDiscounted += component.rawDiscountedPrice * quantity
          total += component.rawPrice * quantity
        }
      }

      this.totalQuantity = totalQuantity
      this.totalAmount   = totalDiscounted
      this.totalSavings  = total - totalDiscounted

      this.$parent.optionVariantsTableFilled = (total > 0)
    }
  }
}
</script>