import { mapState, mapGetters } from 'vuex'

export default {
  methods: {
    /*
     * Loads the saved orders from the API. Its called once when app is mounted.
     */
    loadSavedOrders () {
      this.$http.get(`${this.apiPath}/saved_orders`).then(response => {
        this.savedOrders = response.body.saved_orders
        this.$store.dispatch('triggerEvent', 'gy::saved-orders-loaded')
      })
    },

    /*
     * Adds, removes or manipulates custom attributes
     * @param {savedOrder} the saved order to manipulate
     */
    handleCustomAttributes (savedOrder) {
      savedOrder.check_all = false

      for (var i = 0, len = savedOrder.line_items.length; i < len; i++) {
        savedOrder.line_items[i].is_checked = false
      }
    }
  }
}
