<template>
  <div><slot /></div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '../lib/event_bus.js'

import StoreFilters from '../components/stores/store_filters.vue'
import List from '../components/stores/store_list.vue'
import StoreDetail from '../components/stores/store_detail.vue'
import Map from '../components/stores/map.vue'
import MapInfoContent from '../components/stores/map_info_content.vue'

export default Vue.component('stores', {
  props: [],
  data() {
    return {
      stores: [],
      filteredStores: [],
      visibleStores: [],
      storeTypes: [],
      selectedStore: {},
      selectedFilters: {},
      tmt: null
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      }

      return undefined
    }
  },
  mounted() {
    this.loadStoreTypes()
    this.loadStores()

    EventBus.$on('store-filter-selected', this.filterSelected)
    EventBus.$on('visible-in-map-stores-refreshed', this.setVisibleStores)
    EventBus.$on('store-selected', this.setSelectedStore)
    EventBus.$on('selected-store-set', this.setSelectedStore)
  },
  methods: {
    loadStoreTypes() {
      this.$http.get(`${this.apiPath}/store_types`).then(response => {
        this.storeTypes = response.body.store_types

        this.resetSelectedFilters()
      })
    },

    loadStores() {
      this.$http.get(`${this.apiPath}/stores`, { params: { versions: this.stringifiedVersions } }).then(response => {
        this.stores = response.body.stores

        this.filterStores()
      })
    },

    filterStores() {
      var filteredStores = []

      var time = Date.now()

      for (let store of this.stores) {
        var include = true

        // Check if filters have been selected
        var filtersMatched = []

        if (Object.keys(this.selectedFilters).length > 0) {
          for (let key of Object.keys(this.selectedFilters)) {
            // Take intersection of two arrays [] | [], and find if there is any match
            filtersMatched.push(this.intersection(store.store_type_ids, this.selectedFilters[key]).length > 0)
          }
        }

        include = filtersMatched.every(function(value) { return value })

        if (include)
          filteredStores.push(store)
      }

      var now = Date.now()

      this.filteredStores = filteredStores

      if (this.$refs.map) this.$refs.map.refreshVisibleInMapStores()

      this.setVisibleStores()

      return
    },

    filterSelected(filter) {
      let rootFilter      = this.rootFilter(filter)
      let selectedFilters = this.selectedFilters[rootFilter.handle]

      if (selectedFilters != undefined) {
        var idx = this.selectedFilters[rootFilter.handle].indexOf(filter.id)

        // Exists already, delete it
        if (idx > -1 && rootFilter.ability_to_deselect) {
          // If we have one element or less, just delete the key from hash
          if (this.selectedFilters[rootFilter.handle].length <= 1) {
            delete this.selectedFilters[rootFilter.handle]
          } else {
            this.selectedFilters[rootFilter.handle].splice(idx, 1)
          }
        } else {
          this.selectedFilters[rootFilter.handle] = [filter.id]
        }
      } else {
        this.selectedFilters[rootFilter.handle] = [filter.id]
      }

      // Set also #selected attribute for all filters, for same group
      for (let storeType of this.storeTypes) {
        if (storeType.handle == rootFilter.handle && storeType.children.length > 0) {
          for (let childFilter of storeType.children) {
            if (this.selectedFilters[storeType.handle] && this.selectedFilters[storeType.handle].indexOf(childFilter.id) > -1) {
              childFilter.selected = true
            } else {
              childFilter.selected = false
            }
          }
        }
      }

      this.filterStores()
    },

    resetSelectedFilters() {
      var filters = {}

      for (let rootFilter of this.storeTypes) {
        for (let filter of rootFilter.children) {
          if (filter.selected) {
            let rootFilter = this.rootFilter(filter)

            if (filters[rootFilter.handle] == undefined) {
              filters[rootFilter.handle] = []
            }

            filters[rootFilter.handle].push(filter.id)
          }
        }
      }

      this.selectedFilters = filters
    },

    rootFilter(filter) {
      for (let rootFilter of this.storeTypes) {
        var detectedFilter = this.detectFilter(filter, rootFilter.children)

        if (detectedFilter != undefined)
          return rootFilter
      }
    },

    detectFilter(filter, filters = []) {
      for (let f of filters) {
        if (filter.id == f.id)
          return filter

        this.detectFilter(filter, f.children)
      }
    },

    selectedFilterIds() {
      return [].concat.apply([], Object.values(this.selectedFilters)).map((x) => x.id)
    },

    updateMapCenter(event) {
      if (!this.$refs.map) {
        alert("Please set ref=\"map\" to your <stores-map> component!")
      }

      this.$refs.map.updateCenter(event)
    },

    setVisibleStores(stores = this.stores) {
      clearTimeout(this.tmt)

      this.tmt = setTimeout(() => {
        this.visibleStores = this.intersection(this.stores, this.filteredStores, stores)
      }, 50)
    },

    intersection() {
      return Array.from(arguments).reduce(function(previous, current) {
        return previous.filter(function(element) {
          return current.indexOf(element) > -1
        })
      })
    },

    setSelectedStore(store) {
      this.selectedStore = store
    }
  }
})
</script>
