<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store }    from '../../lib/store'
import VueCookie    from 'vue-cookie'
import NiceI18n     from '../../lib/nice_i18n'

import Utils        from '../../lib/utils'
import { EventBus } from '../../lib/event_bus.js'

Vue.use(VueCookie)

export default Vue.component('gift-lists', {
  props: {
    giftListTypeId: {
      type: Number,
      default: undefined
    },
    selectedGiftListId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState([
      'order',
      'giftListsLoaded',
      'activeGiftListId',
      'displayGiftListBar'
    ]),
    ...mapGetters([
      'apiPath',
      'activeGiftList'
    ]),
    giftlistsEmpty() {
      return (this.$store.state.giftLists || []).length == 0
    },
    activeGiftListItemsCount() {
      if (this.activeGiftList == undefined) return 0
      if (this.activeGiftList.items == undefined) return 0
      return this.activeGiftList.items.length
    },
    selectedGiftList() {
      return (Utils.detect(this.this.$store.state.giftLists, this.selectedGiftListId) || {})
    },
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      } else {
        return undefined
      }
    },
    giftLists() {
      // TODO: Improve this logic so as to not have to load all gift lists and then filter,
      // but load the needed ones only. This fix is a hotfix for the product page issue
      // in Lapin where both drop-downs had the same gift lists.
      if (this.giftListTypeId)
        return this.$store.state.giftLists.filter(giftList => giftList.gift_list_type_id == this.giftListTypeId)
      else
        return this.$store.state.giftLists
    }
  },
  mounted() {
    this.$store.state.displayGiftListBar = (this.$cookie.get('gift-list-bar-display') == 'true')

    EventBus.$on("reload-gift-lists", this.loadGiftLists)

    if (!this.giftListsLoaded) {
      this.loadGiftLists()
      this.$store.state.giftListsLoaded = true
    }
  },
  methods: {
    selectedGiftListEmpty() {
      return (this.selectedGiftList.items || {}).length == 0
    },

    /*
     * Loads the order from the API. Its called once when app is mounted.
     * @param {Bolean} is_miniwishlist_item - Default value is true
     */
    loadGiftLists() {
      this.$http.get(`${this.apiPath}/gift_lists`, { params: { versions: this.stringifiedVersions } }).then(response => {
        this.$store.commit('setGiftLists', response.body.gift_lists)
        EventBus.$emit("select-option")
      })
    },

    /*
     * Triggers popup to create a new gift list
     */
    create() {
      this.$http.get(`${this.apiPath}/gift_lists/new`, { params: { gift_list_type_id: this.giftListTypeId } }).then(response => {
        EventBus.$emit("populate-giftlist-form", response.body.gift_list)

        this.$store.dispatch('triggerEvent', 'gy::giftlist-form-populated')
      })
    },

    /*
     * Adds Product To Gift List
     * @param {string} giftlist_id - id of gift list
     * @param {string} product_id  - id of product_to_add
     */
    addProduct(giftlist_id, product_id) {
      this.$http.post(`${this.apiPath}/gift_lists_items`, { gift_list_id: giftlist_id, product_id: product_id }).then(response => {
        EventBus.$emit("select-option", { id: parseInt(giftlist_id) })
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-product-added', message: response.body.message })
      },
      (error) => {
        this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-product-not-added', message: error.body.message })
      })
    },

    /*
     * Triggers share popup
     */
    share() {
      this.$store.dispatch('triggerEvent', 'gy::giftlist-share')
    },

    shareText() {
      return this.activeGiftList.state == "completed" ? NiceI18n.t('gift_lists.url.share_again') : NiceI18n.t('gift_lists.url.share')
    },

    toggleGiftListBarDisplayText() {
      return this.displayGiftListBar ? NiceI18n.t('gift_lists.show_giftlists') : NiceI18n.t('gift_lists.hide_giftlists')
    },

    /*
     * Toggles display of gift list bar
     */
    toggleGiftListBarDisplay() {
      this.$store.state.displayGiftListBar = !this.displayGiftListBar
      this.$cookie.set('gift-list-bar-display', this.displayGiftListBar )
    },

    /*
     * Marks a list as completed
     */
    setListCompleted() {
      if (this.activeGiftList.state != 'completed') {
        this.$http.post(`${this.apiPath}/gift_lists/${this.activeGiftListId}/set_completed`).then(response => {
          this.$store.dispatch('triggerEvent', 'gy::giftlist-set-completed')
          this.loadGiftLists()
        },
        (error) => {
          this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-set-completed-error', message: error.body.message })
        })
      }
    }
  }
})
</script>
