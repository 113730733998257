<script>
import axios from 'axios'
import groupBy from 'lodash/groupBy'
import { mapState, mapGetters } from 'vuex'
import GroupedLineItem from './GroupedLineItem.vue'
import versionsMixin from '../../mixins/versions_mixin'

export default {
  props: {
    id: {
      required: false,
      type: Number
    }
  },
  components: {
    GroupedLineItem
  },
  mixins: [versionsMixin],
  data() {
    return {
      pastOrder: {}
    }
  },
  computed: {
    order() {
      if (this.shouldShowPastOrder) {
        return this.pastOrder
      } else {
        return this.$store.state.order
      }
    },
    shouldShowPastOrder() {
      return this.id !== undefined
    },
    groupedLineItems() {
      if (this.order === undefined || this.order.line_items === undefined) {
        return {}
      }

      return groupBy(this.order.line_items, (item) => {
        return [item.product_id, item.option_variant_id]
      })
    },
    ...mapState([
      'user',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    if (this.shouldShowPastOrder)
      this.loadPastOrder()
  },
  methods: {
    loadPastOrder() {
      return axios.get(
        `${this.apiPath}/order/${this.id}`, { params: { versions: this.stringifiedVersions } }
      ).then((response) => {
        this.pastOrder = response.data.order
      })
    }
  }
}
</script>