import Vue from 'vue'
import { store } from '../lib/store'
import Promotions from '../components/promotions/promotions.vue'

export default class Product {
  constructor() {
    this.apps = {}
    this.idx  = 1
    this.initializeAll()
  }

  clearApps() {
    for (let key of Object.keys(this.apps)) {
      this.apps[key].$destroy()
    }

    this.apps = {}
  }

  initializeAll() {
    const $this    = this
    const products = document.querySelectorAll(".gy-product")

    // Use IntersectionObserver to lazy mount VueJS instances
    if ("IntersectionObserver" in window) {
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            $this.prepare(entry.target)
          }
        })
      },
      {
        rootMargin: '0px 0px 60px 0px'
      })

      products.forEach(product => {
        if (product.hasAttribute("lazyMount")) {
          observer.observe(product)
        } else {
          $this.prepare(product)
        }
      })
    }
    else {
      products.forEach(product => {
        $this.prepare(product)
      })
    }
  }

  prepare(element) {
    let id = "gy-product-" + Date.now() + "-" + this.idx
    element.setAttribute("id", id)

    if (this.apps[id] == undefined) {
      this.apps[id] = this.initialize(element)
    }

    this.idx++
  }

  /*
   * Initialize Vue app
   */
  initialize(product) {
    return new Vue({
      el: product,
      store,
      components: {
        Promotions
      },
      data() {
        return {
          versions: {},
          productId: null,
          load: false
        }
      },
      computed: {
        alreadyAddedToCart() {
          if (this.$children[0]) {
            return this.$children[0].alreadyAddedToCart()
          }
          else {
            return false
          }
        }
      },
      beforeMount() {
        if (this.$el.attributes.productId) {
          this.productId = this.$el.attributes.productId.value
        }

        if (this.$el.attributes.versions) {
          this.versions = this.$el.attributes.versions.value
        }

        if (this.$el.attributes.load) {
          this.load = true
        }
      },
      mounted() {
        this.$store.dispatch('triggerEvent', {
          type: 'gy::product-mounted',
          message: {
            product: this,
            el: product
          }
        })
      }
    })
  }
}
