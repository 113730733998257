/*
 * Used to store state of Ajax calls (when ajax request is in progress), to avoid
 * multiple same calls to API.
 *
 * Example of usage:
 *
 *    let state = AjaxState.initialize("paymentMethods")
 *    if (state.fired) return
 *
 *    state.fire()
 *    ...
 *    Do something asynchonous
 *    ...
 *    .then(response => {
 *      state.clear()
 *   }
 */
export default class AjaxState {
  static initialize(type) {
    if (this.states === undefined) this.states = {}

    if (this.states[type] === undefined) {
      this.states[type] = new this(type)
    }

    return this.states[type]
  }

  constructor(type) {
    this.type  = type
    this.fired = false
  }

  fire() {
    this.fired = true
  }

  clear() {
    this.fired = false
  }
}
