<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import PromotionImage from './promotion_image.vue'
import BonusProduct from './bonus_product.vue'

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    PromotionImage,
    /* eslint-disable vue/no-unused-components */
    BonusProduct
  },
  props: {
    promotion: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      reserved: 0,
      active: false
    }
  },
  computed: {
    sumOfEligibleQuantities() {
      if (this.order.eligible_bonus_products == undefined) {
        return 0
      }

      return this.order.eligible_bonus_products.reduce((accumulator, el) => accumulator + el.quantity, 0) - this.reserved
    },
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    this.$on('reserve-quantity', this.reserveQuantity)
    this.$on('release-quantity', this.releaseQuantity)

    this.$nextTick(() => {
      this.checkIfActive()
    })
  },
  methods: {
    reserveQuantity(quantity) {
      this.reserved += quantity
    },
    releaseQuantity(quantity) {
      if (this.reserved > 0)
        this.reserved -= quantity
    },
    checkIfActive() {
      if (this.$refs.product == undefined) {
        this.active = false
        return
      }

      for (let component of this.$refs.product) {
        if (component.enabled && !component.chosenStatus) {
          this.active = true
          return
        }
      }

      this.active = false
    }
  }
}
</script>
