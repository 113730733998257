<template>
  <div class="gift-card-price">
    <span class="gift-card-price__value">
      <AnimatedNumber
        v-if="!isNaN(giftCardValue)"
        :value="giftCardValue"
        :duration="2000"
        :round="1"
        easing="easeOutSine"
      />
    </span>
    <span class="gift-card-price__currency">
      {{ currentCurrency.symbol }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { store } from '../../lib/store'
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    AnimatedNumber
  },
  computed: {
    ...mapState([
      'currentCurrency',
      'giftCardValue'
    ])
  }
}
</script>
