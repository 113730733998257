<template>
  <iframe
    id="secure3d-frame"
    name="secure3d-frame"
  />
</template>

<script>
import Simplify from '../../classes/rothschild/simplify'

export default {
  props: {
    gateway: {
      type: Object
    }
  },
  computed: {
    data() {
      if (!this.gateway.card || !this.gateway.card.secure3DData)
        return {}

      return this.gateway.card.secure3DData
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      let form = document.createElement('form')
      form.setAttribute('method', 'POST')
      form.setAttribute('action', this.data.acsUrl)
      form.setAttribute('target', 'secure3d-frame')

      let merchantDetails = this.data.md;
      let paReq = this.data.paReq;
      let termUrl = this.data.termUrl;

      form.append(this.createInput('PaReq', paReq))
      form.append(this.createInput('TermUrl', termUrl))
      form.append(this.createInput('MD', merchantDetails))

      let iframe = document.getElementById('secure3d-frame')
      iframe.parentNode.insertBefore(form, iframe)

      let process3dSecureCallback = (response) => {
        console.log('Processing 3D Secure callback...');
        window.removeEventListener('message', process3dSecureCallback)

        let simplifyDomain = 'https://www.simplify.com'

        // Secure 3D has been authenticated, continue with transaction
        if (response.origin === simplifyDomain) {
          if (JSON.parse(response.data)['secure3d']['authenticated']) {
            this.gateway.generateTransaction()
          } else {
            this.gateway.error = JSON.parse(response.data)['secure3d']['error']
            this.gateway.emmitErrors()
          }

          // Set undefined secure3DData in order to not have any data when we load again this component
          // Avoids issue where we post the old secure 3D data again
          this.gateway.card.secure3DData = undefined
        }
      }

      iframe.addEventListener('load', function() {
        window.addEventListener('message', process3dSecureCallback) // Step 3
      })

      form.submit()
    },
    createInput(name, value) {
      let input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', value);
      return input
    }
  }
}
</script>

<style lang="scss" scoped>
  iframe {
    width: 100%;
    min-height: 380px;
  }
</style>