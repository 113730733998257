import { EventBus } from '../../lib/event_bus.js'
import axios from 'axios'

export default class Everypay {
  constructor(clientId, transactionUrl, order) {
    this.clientId       = clientId
    this.transactionUrl = transactionUrl
    this.order          = order
    this.txnType        = 'tds'
    this.loading        = false

    this.rothschildApiPath = 'https://secure.gy.digital/api/v1'
    // this.rothschildApiPath = 'http://localhost:4000/api/v1'
  }

  generateToken() {
    let responseHandler = (response) => {
      if (response.onLoad) {
        document.getElementById('loader').style.display = 'none';
      }
      else if (response.response === 'success') {
        this.token = response.token
        this.generateTransaction()

        document.getElementById('loader').style.display = 'block';
      }
      else if (response.response === 'error') {
      }
    }

    const billingRegionTitle  = this.order.billing_address.region.title
    const shippingRegionTitle = this.order.shipping_address.region.title

    axios.post(`${this.rothschildApiPath}/regions`, {
      billing_region_title: billingRegionTitle,
      shipping_region_title: shippingRegionTitle
    }).then(response => {
      this.billingRegionIsoCode  = response.data.regions.billing_region.iso_subdivision_code
      this.shippingRegionIsoCode = response.data.regions.shipping_region.iso_subdivision_code

      const payLoad = {
        pk: this.clientId,
        amount: parseInt(this.order.final_cost * 100),
        locale: window.locale,
        txnType: this.txnType,
        data: this.tokenData()
      }

      setTimeout(() => {
        everypay.payform(payLoad, responseHandler)
      }, 1000)
    })
  }

  generateTransaction() {
    let $this = this

    axios.post($this.transactionUrl, {
      payment_id: $this.token
    }).then(response => {
      setTimeout(() => {
        if (response.data.success) {
          window.location.href = response.data.url
        }
        else {
          EventBus.$emit('everypay-error-occured', response.data.status)
          document.getElementById('loader').style.display = 'none';
        }

      }, 1500)
    })
  }

  tokenData() {
    let data     = {}
    let billing  = this.order.billing_address
    let shipping = this.order.shipping_address

    data.email    = billing.email
    data.phone    = billing.mobile !== null ? billing.mobile : billing.telephone
    data.siteLang = window.locale

    data.billing              = {}
    data.billing.country      = billing.country.code
    data.billing.state        = this.billingRegionIsoCode
    data.billing.postalCode   = billing.zip
    data.billing.addressLine1 = [billing.street, billing.street_number].join(' ').trim()

    data.shipping              = {}
    data.shipping.country      = shipping.country.code
    data.shipping.state        = this.shippingRegionIsoCode
    data.shipping.postalCode   = shipping.zip
    data.shipping.addressLine1 = [shipping.street, shipping.street_number].join(' ').trim()

    return data
  }
}
