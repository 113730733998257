<script>
import Vue from 'vue'
import debounce from 'lodash/debounce'
import { mapState, mapGetters } from 'vuex'
import * as api from '../api/api'
import checkout from '../apps/checkout'
import NiceI18n from '../lib/nice_i18n'
import Coupon from '../components/coupon.vue'

export default Vue.component('line-item', {
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    Coupon
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    quantities() {
      // Creates an range array from 1...this.maxQuantity, with steps based on
      // item.quantity_step. If minimum purchase quantity is 10 it will create
      // options based on steps of 10.
      const step   = (this.quantityStep > 0) ? this.quantityStep : 1
      const length = Math.floor(this.item.max_quantity / step)
      return Array(length).fill().map((_, i) => (i + 1) * step)
    },
    quantityStep() {
      return this.item.quantity_step || 0
    }
  },
  watch: {
    'item.quantity': debounce(function(newQuantity, oldQuantity) {
      if (newQuantity != oldQuantity)
        this.updateQuantity()
    }, 250)
  },
  methods: {
    /*
     * It deletes the line item from the order.
     * @event - gy::line-item-deleted event is triggered
     */
    deleteLineItem() {
      api.deleteLineItem(this.apiPath, this.item.id).then(response => {
        this.$store.dispatch('loadOrder')

        checkout.refreshPaymentMethods()
        checkout.refreshShippingMethods()

        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-deleted', message: response.data.message, item: this.item })
      })
    },

    /*
     * It updates quantity for line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    updateQuantity: debounce(function () {
      // Delete line item if we input zero quantity or less
      if (Number(this.item.quantity) < 1) {
        this.deleteLineItem()
        return
      }

      this.$http.put(`${this.apiPath}/line_items/${this.item.id}`, { quantity: this.item.quantity }).then(response => {
        this.$store.dispatch('loadOrder')

        checkout.refreshPaymentMethods()
        checkout.refreshShippingMethods()

        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-quantity-updated', message: response.body.message })
      })
    }, 100),

    /*
     * It increases by 1 the quantity of the line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    increaseQuantity() {
      if (this.item.quantity < this.item.max_quantity)
        this.item.quantity = this.item.quantity + 1
      else
        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-max-quantity-reached', message: NiceI18n.t("flashes.line_item_max_quantity_reached") })
    },

    /*
     * It decreases by 1 the quantity of the line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    decreaseQuantity() {
      if (this.item.quantity > 1)
        this.item.quantity = this.item.quantity - 1
    }

  },
  template: '#line-item-template'
})
</script>