<script>
require('es6-promise/auto')

import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default Vue.component('bundle', {
  props: {
    bundle: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      addedToCart: false,
      clickedWhenDisabled: false,
      errors: []
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    bundlePresent() {
      return (this.bundle.id)
    },

    /*
     * Boolean (true/false) that returns if we have any error.
     */
    hasErrors() {
      return this.errors.length > 0
    }
  },
  methods: {
    /*
     * Adds bundle to cart
     * @event - gy::bundle-added-to-cart event is triggered
     */
    addToCart() {
      this.errors = []
      let $this   = this

      let $promise = new Promise( (resolve, reject) => {
        let products = []
        let promises = []

        // Loop all elements and call loadOptions using promise.
        for (let element of this.$children) {
          if (element.options.length == 0) {
            promises.push(new Promise(resolve => { resolve(element.loadOptions()) }))
          }
        }

        // Check for all products of bundle if user has selected color/size etc. If yes, continue.
        Promise.all(promises).then(response => {
          for (let element of this.$children) {
            if (element.optionsValidated()) {
              let product_id = element.product ? element.product.id : Number(element.productId)

              products.push({ product_id: product_id, options: element.selected, quantity: element.quantity })
            } else {
              this.errors.push(element.errors)
            }
          }

          if (this.errors.length == 0) {
            resolve(products)
          } else {
            $this.clickedWhenDisabled = true
            let errorMessages = []

            for (let key of Object.keys(this.errors)) {
              var option = this.errors[key]
              errorMessages.push(option.title)
            }

            setTimeout(() => {
              $this.clickedWhenDisabled = false
            }, 800)

            this.$store.dispatch('triggerEvent', { type: 'gy::bundle-cannot-add-to-cart', message: errorMessages.join(', ') })
            reject(this.errors)
          }
        })
      }).then(products => {

        this.$http.post(`${$this.apiPath}/order`, {
          products: products
        }).then(response => {
          this.addedToCart = true
          this.$store.dispatch('loadOrder')

          this.$store.dispatch('triggerEvent', { type: 'gy::bundle-added-to-cart', message: response.body.message })

          setTimeout(() => {
            $this.addedToCart = false
          }, 4200)
        })
      })
    }
  }
})
</script>