<template>
  <div id="gy-site-selector">
    <ModalsContainer />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import * as Cookies from 'js-cookie'
import VModal from 'vue-js-modal'
import NiceI18n from '../lib/nice_i18n'
import SiteSelectorModal from './site_selector_modal.vue'
import versionsMixin from '../mixins/versions_mixin'
import queryString from 'query-string'
import Utils from '../lib/utils'

export default Vue.component('site-selector', {
  components: {
  },
  mixins: [versionsMixin],
  data() {
    return {
      cookieName: 'i18n_site',
      scenario: null,
      sites: [],
      preselectedSite: {},
      visitorCountry: {},
      geoip: undefined
    }
  },
  computed: {
    ...mapState([
      'selectedSiteId'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    sameCountry() {
      return this.scenario == 1
    }
  },
  mounted() {
    // Check if cookie for site selection has been set up
    this.$store.state.selectedSiteId = Cookies.get(this.cookieName)

    // If this is the first visit
    if (this.$store.state.selectedSiteId == null || true) {
      // Get geoip URL parameter
      this.params = queryString.parse(queryString.extract(window.location.href))

      if (!Utils.checkForEmptyObject(this.params) && this.params.geoip) {
        this.geoip = this.params.geoip
      }

      this.$http.get(`${this.apiPath}/sites/popup_selector`, {
        params: {
          geoip: this.geoip,
          versions: this.stringifiedVersions
          }
        }).then(response => {
          this.scenario         = response.body.scenario
          this.sites            = response.body.sites
          this.preselectedSite  = response.body.preselected_site
          this.visitorCountry   = response.body.visitor_country

          if (this.sameCountry)
            Cookies.set(this.cookieName, '1')
          else if (this.sites.length > 0 && Cookies.get(this.cookieName) != '1')
            this.showPopup()
      })
    }
  },
  methods: {
    /*
     * This method shows the i18n modal
     */
    showPopup() {
      this.$modal.show(SiteSelectorModal, {
        cookieName: this.cookieName,
        scenario: this.scenario,
        sites: this.sites,
        preselectedSite: this.preselectedSite,
        visitorCountry: this.visitorCountry
      }, {
        height: "auto",
        width: 500,
        adaptive: true,
        scrollable: false,
        clickToClose: false,
        root: this.$parent
      })
    }
  }
})
</script>

<style lang="scss">
  #gy-site-selector {
    > #modals-container {
      .v--modal-overlay {
        z-index: 20000;
      }
    }
  }
</style>
