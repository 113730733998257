<template>
  <div class="gy-credit-card-modal">
    <div v-if="isSimplify">
      <CardForm
        :form-data="formData"
        :client-id="clientId"
        :type="type"
        :default-card-name="defaultCardName"
        @submit-pay="pay"
        @close-modal="closeModal"
      />
    </div>
    <div v-if="isEverypay">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="text-center" id="loader">
              <div class="spinner-border" role="status" />
            </div>
            <div v-if="error.length === 0">
              <div id="pay-form" class="everypay-form"></div>
            </div>
            <div class="everypay-error" v-if="error.length > 0">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="modal-footer" />
      </div>
    </div>
  </div>
</template>

<script>
import NiceI18n from '../../lib/nice_i18n'
import { EventBus } from '../../lib/event_bus.js'
import CardForm from './CardForm.vue'
import Simplify from '../../classes/rothschild/simplify'
import Everypay from '../../classes/rothschild/everypay'

export default {
  components: {
    CardForm
  },
  props: {
    clientId: {
      type: String
    },
    transactionUrl: {
      type: String
    },
    type: {
      type: String
    },
    order: {
      type: Object
    },
    defaultCardName: {
      type: String
    }
  },
  data() {
    return {
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      },
      provider: null,
      error: ''
    }
  },
  computed: {
    isSimplify() {
      return this.type == 'simplify'
    },
    isEverypay() {
      return this.type == 'everypay'
    }
  },
  mounted() {
    if (this.isSimplify) {
      this.provider = new Simplify(this.clientId, this.transactionUrl, this.order)
    }
    else if (this.isEverypay) {
      this.provider = new Everypay(this.clientId, this.transactionUrl, this.order)

      EventBus.$on('everypay-error-occured', this.setError)
    }
  },
  methods: {
    pay() {
      this.provider.generateToken(this.formData)
    },
    closeModal() {
      this.$emit('close')
    },
    setError(error) {
      this.error = NiceI18n.t('pay_modal.errors.transaction') + error
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/css/style.scss';

  .v--modal-overlay {
    z-index: 16000003;
  }

  .v--modal {
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 768px) {
      width: 96% !important;
      left: 2% !important;
    }
  }

  .gy-credit-card-modal {
    padding: 20px 0 50px 0;
  }

  .everypay-form {
    width: auto !important;
    iframe { width: auto; }
  }

  .everypay-error { color: #FF0000; }
</style>