import { mapState, mapGetters } from 'vuex'

export default {
  mounted() {
    if (this.$el.attributes && this.$el.attributes.versions) {
      this.$store.commit('setWishlistVersions', this.$el.attributes.versions.value)
    }
  },
  computed: {
    ...mapState([
      'user',
      'wishlistItems'
    ]),
    ...mapGetters([
      'apiPath',
      'signedIn'
    ]),
    wishlistEmpty() {
      return this.wishlistItems == undefined || this.wishlistItems.length == 0
    },
    totalQuantity() {
      return (this.wishlistItems || {}).length || 0
    }
  },
  methods: {
    /*
     * It adds all items to cart.
     * @event - gy::wishlist-added-all-to-cart is triggered
     */
    addAllToCart() {
      let messages = []

      for (let item of this.wishlistItems) {
        this.$http.post(`${this.apiPath}/wishlist_items/add_to_cart`, { product_id: item.product_id, options: item.options }).then(response => {
          messages.push(response.body.message)
        })
      }

      let $this = this

      var timer = setInterval( () => {
        if (messages.length == $this.wishlistItems.length) {
          $this.$store.dispatch('loadOrder')
          $this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-added-all-to-cart', message: messages[0] })

          clearInterval(timer)
        }
      }, 30)
    },

    deleteAllWishlistItems() {
      this.$http.post(`${this.apiPath}/wishlist_items/destroy_all`).then(response => {
        this.$store.dispatch('loadWishlistItems')
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-deleted-all', message: response.body.message })
      })
    }
  }
}