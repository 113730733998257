<template>
  <div>
    <input
      v-if="address"
      v-model="address.vat_number"
      type="text"
      :placeholder="placeholder"
      @keyup="runValidation"
    >
    <input
      v-if="!address"
      :id="inputId"
      v-model="vatNumber"
      type="text"
      :name="inputName"
      :placeholder="placeholder"
      @keyup="runValidation"
    >
    <span
      v-if="!validNumber"
      class="error-class"
    >
      {{ errorMessage }}
    </span>
    <span
      v-if="(validNumber && !validVat) && vatNumber !== ''"
      class="warning-class"
    >
      {{ errorValidation }}
    </span>
    <span
      v-if="(validNumber && validVat) && vatNumber !== ''"
      class="success-class"
    >
      {{ successMessage }}
    </span>
  </div>
</template>

<style>
  .error-class { color: red; }

  .warning-class { color: orange; }

  .success-class { color: green;}
</style>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { validateVat } from '../api/api'
import { debounce } from 'lodash'

import NiceI18n    from '../lib/nice_i18n'

export default Vue.component('vat-number-field', {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    address: {
      default: () => {
        return undefined
      },
      type: Object
    },
    inputName: {
      type: String,
      default: undefined
    },
    inputId: {
      default: undefined
    }
  },
  data() {
    return {
      vatNumber: '',
      validNumber: true,
      validVat: false,
      errorMessage: NiceI18n.t("activerecord.errors.models.address.attributes.vat_number.invalid.requirements"),
      errorValidation: NiceI18n.t("activerecord.errors.models.address.attributes.vat_number.invalid.response"),
      successMessage: NiceI18n.t("activerecord.models.address.attributes.vat_number.valid.response")
    }
  },
  computed: {
    ...mapState([
      "order"
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    countryId() {
      if (this.address && this.address.country_id) {
        return this.address.country_id
      }
      else {
        return this.$store.state["billing_address"].country.id
      }
    }
  },
  methods: {
    runValidation: debounce(function (e) {
      validateVat(this.apiPath, this.vatNumber, this.countryId).then(response => {
        this.validNumber = !(response.data.existing_vat_number == undefined)
        this.validVat = response.data.existing_vat_number?.is_valid

        this.hideExistingErrorField()
      }).catch(e => {
        console.log(e)
      })
    }, 500),

    hideExistingErrorField() {
      if (this.$parent.$refs.errorField) {
        this.$parent.$refs.errorField.style.display = 'none'
      }
    }
  }
})
</script>