<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { store } from '../lib/store'

export default Vue.component('product-store-availability', {
  props: ['productId', 'sizeOption', 'sizes', 'selected'],
  store,
  data() {
    return {
      optionVariant: "",
      stores: [],
      message: "",
      errors: [],
      loading: false
    }
  },
  mounted() {
  },
  watch: {
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ]),
    selectedOptions() {
      let selectedOptions = this.selected
      selectedOptions[this.sizeOption.id] = this.optionVariant.id

      return selectedOptions
    },
    canRequestAvailability() {
      return Boolean(this.optionVariant) && !this.loading
    }
  },
  methods: {
    /*
     * Requests store availability for the given product
     */
    requestStoreAvailability() {
      if (this.loading) {
        return false
      }

      this.loading = true

      this.$store.dispatch('triggerEvent', 'gy::product-store-availability-started')

      return this.$http.post(`${this.apiPath}/products/${this.productId}/store_availability`, {
        options: this.selectedOptions
      }).then(response => {
        this.stores = response.body.stores
        this.message = response.body.message
        this.errors = response.body.errors

        this.$store.dispatch('triggerEvent', {
          type: 'gy::product-store-availability-completed',
          message: {
            message: response.body.message,
            errors: response.body.errors
          }
        })

        this.restoreOptionVariant()
        this.loading = false
      }, response => {
        this.$store.dispatch('triggerEvent', 'gy::product-store-availability-error')
        this.loading = false
      })
    },

    restoreOptionVariant() {
      this.optionVariant = ""
    }
  }
})
</script>
