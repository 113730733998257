<template>
  <div>
    <input
      :id="id"
      v-model="zipCode"
      type="text"
      :name="name"
      :placeholder="placeholder"
      @keyup="clearError"
      @blur="validate"
    >
    <span
      v-if="!valid"
      class="error"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { validateZipCode } from "../../api/api"
import { EventBus } from '../../lib/event_bus'
import NiceI18n from '../../lib/nice_i18n'

export default {
  props: {
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: undefined
    },
    id: {
      default: undefined
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      zipCode: '',
      valid: true,
      errorMessage: NiceI18n.t("activerecord.errors.models.address.attributes.zip.invalid")
    }
  },
  computed: {
    countryId() {
      let country = (this.$store.state[this.type] || {}).country

      if (!country)
        return

      return country.id
    },
    ...mapState([
      'sameAsBilling'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    'zipCode': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.debouncedValidate()
      }
    }
  },
  mounted() {
    if (this.value.length > 0) {
      this.zipCode = this.value
      this.validate()

      let $this = this
      EventBus.$on('checkout-address-selected', (selectedAddress) => {
        $this.updateZipCodeByAddress(selectedAddress)
      })

      // This event is triggered when clicking sameAsBilling checkbox
      EventBus.$on('update-shipping-zip', (billingZipCode) => {
        $this.setBillingAsZipcode(billingZipCode)
      })
    }
  },
  created: function () {
    this.debouncedValidate = debounce(this.validate, 500)
  },
  methods: {
    validate: debounce(function (e) {
      validateZipCode(this.apiPath, this.zipCode, this.countryId).then(response => {
        this.valid = response.data.valid
      }).catch((e) => {
        console.log(e)
      })

      this.$store.commit('setAddress', { type: this.type, key: 'zip', value: this.zipCode })

      if (this.type == 'billing_address' && this.sameAsBilling) {
        this.$store.commit('setAddress', { type: 'shipping_address', key: 'zip', value: this.zipCode })
      }
    }, 20),

    clearError() {
      if (this.valid)
        return

      this.valid = true
    },

    updateZipCodeByAddress: function(selectedAddress) {
      if (this.type == 'billing_address')
        this.zipCode = selectedAddress.zip

      if (this.type == 'shipping_address' && this.sameAsBilling)
        this.zipCode = selectedAddress.zip
    },

    setBillingAsZipcode: function(billingZipCode) {
      if (billingZipCode)
        this.zipCode = billingZipCode
    }
  }
}
</script>
