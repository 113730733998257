<template>
  <div><slot /></div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../../lib/store'

import scroll from 'scroll'
var page = require('scroll-doc')()
var ease = require('ease-component')

import Promotion from './promotion.vue'
import versionsMixin from '../../mixins/versions_mixin'

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    Promotion
  },
  mixins: [versionsMixin],
  props: {
    productId: {
      type: Number,
      default: null
    },
    autoscroll: {
      type: Boolean,
      default: false
    }
  },
  store,
  computed: {
    withBonusProducts() {
      return this.promotions.filter((el) =>
        el.bonus_products.length > 0
      )[0]
    },
    withChoiceOfBonusProducts() {
      return this.promotions.filter((el) =>
        el.choice_of_bonus_products.length > 0
      )[0]
    },
    ...mapState([
      'order',
      'recentlyAdded',
      'promotions'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    recentlyAdded: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.scrollToPromotion()
      }
    }
  },
  mounted() {
    this.loadPromotions()
  },
  methods: {
    loadPromotions() {
      this.$http.get(`${this.apiPath}/products/${this.productId}/promotions`, { params: { versions: this.stringifiedVersions } }).then(response => {
        this.$store.state.promotions = response.body.promotions

        this.$store.dispatch('triggerEvent', 'gy::promotions-loaded')
      })
    },
    scrollToPromotion() {

      if (!this.autoscroll) {
        return
      }

      if (this.$refs["promotion-with-choice"] != undefined) {
        let promotion = this.$refs["promotion-with-choice"]

        setTimeout(() => {
          if (promotion) {
            promotion.checkIfActive()

            if (promotion.active) {
              let top = promotion.$el.offsetTop || promotion.$el.offsetParent.offsetTop

              if (top > 0) {
                let y = top - 20
                scroll.top(page, y, { duration: 800, ease: ease.inOutSine })
              }
            }
          }
        }, 600)
      }
    }
  }
}
</script>
