<template>
  <a
    href="#"
    :class="{ selected: selected(filter) }"
    @click.prevent="select(filter)"
  >
    {{ filter.title }}
  </a>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('store-filter', {
  props: {
    filter: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  methods: {
    select(filter) {
      EventBus.$emit('store-filter-selected', filter)
      this.$store.dispatch('triggerEvent', { type: 'gy::store-filter-selected', message: filter })
    },
    selected(filter) {
      return filter.selected
    }
  }
})
</script>