<template>
  <div
    v-show="isVisible"
    style="display: inline;"
  >
    <MultiSelect
      :options="addresses"
      :value="selectedAddress"
      label="description"
      track-by="id"
      :placeholder="placeholder"
      :selected-label="selectedLabel"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :close-on-select="true"
      :clear-on-select="true"
      @select="select"
      @remove="remove"
    />
    <input
      v-model="address.id"
      type="hidden"
      :name="inputName"
    >
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import { EventBus } from '../lib/event_bus.js'
import MultiSelect  from '../lib/vue-multiselect'
import Utils        from '../lib/utils'
import NiceI18n     from '../lib/nice_i18n'

import { validationMixin }    from 'vuelidate'
import { required, numeric }  from 'vuelidate/lib/validators'

export default Vue.component('address-select', {
  components: {
    MultiSelect
  },
  mixins: [validationMixin],
  props: {
    placeholder: {
      type: String,
      default: NiceI18n.t("orders.address.select_address")
    },
    selectedLabel: {
      type: String,
      default: ""
    },
    selectLabel: {
      type: String,
      default: ""
    },
    deselectLabel: {
      type: String,
      default: ""
    },
    selectedAddressId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: undefined
    },
    hideWhenSameAsBilling: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      addresses: [],
      selectedAddress: {},
      attributes: [],
      vatAttributes: ["company", "vat_number", "profession", "vat_office_id"]
    }
  },
  computed: {
    ...mapState([
      'order',
      'sameAsBilling'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    address() {
      return this.selectedAddress
    },
    inputName() {
      return `order[${this.type}_attributes][id]`
    },
    isVisible() {
      return !(this.type == 'shipping_address' && this.hideWhenSameAsBilling && this.sameAsBilling)
    }
  },
  validations: {
    address: {
      id: {
        numeric
      }
    }
  },
  watch: {
    addresses: function(newValue, oldValue) {
      if (oldValue != newValue) {
        let address = this.addresses.filter((address) =>
          address.id == this.selectedAddressId
        )[0]

        if (address) this.select(address)
      }
    }
  },
  mounted() {
    this.loadAddresses()
  },
  methods: {
    /*
     * Fetches all user addresses from api.
     * @event - gy:addresses-loaded is triggered
     */
    loadAddresses() {
      this.$http.get(`${this.apiPath}/addresses`).then(response => {
        this.addresses = response.body.addresses

        if (this.addresses[0]) this.storeAttributes(this.addresses[0])

        this.$store.dispatch('triggerEvent', 'gy:addresses-loaded')
      })
    },

    /*
     * Stores the address attributes after having
     * fetched the addresses from the API
     */
    storeAttributes(address) {
      const h = Object.entries(address)

      for (var k in h) {
        if (h.hasOwnProperty(k)) {
          this.attributes.push(h[k][0])
        }
      }
    },

    /*
     * It selects the given option.
     * @param {Object} option - the option
     */
    select(option) {
      this.selectedAddress = option

      const h = Object.entries(option)

      for (var k in h) {
        if (h.hasOwnProperty(k)) {
          this.$store.commit("setAddress", { type: this.type, key: h[k][0], value: h[k][1] })
        }
      }

      let vatPopulated = false
      let invoiceField = null

      // Copy address details
      for (var k in this.attributes) {
        let key = this.attributes[k]
        let value = option[key]

        value = typeof(value) != 'undefined' ? value : ""

        if (key == "id") {
          continue
        } else if (this.vatAttributes.includes(key)) {
          if (value) vatPopulated = true
        }

        let selector = `#order_${this.type}_attributes_${key}`
        let el = document.querySelector(selector)
        if (el) el.value = value
      }

      // If any VAT-related field is selected, click the invoice radio button
      if (vatPopulated) {
        invoiceField = "invoice"
      } else {
        invoiceField = "receipt"
      }
      Utils.simulateClick( document.querySelector(`#${invoiceField}`) )

      EventBus.$emit("checkout-address-selected", this.selectedAddress)
    },

    /*
     * Removes the address from the store
     */
    remove() {
      this.selectedAddress = {}
      this.selectedAddressId = null

      for (var k in this.attributes) {
        this.$store.commit("setAddress", { type: this.type, key: this.attributes[k], value: null })
      }

      // Delete all address details
      for (var k in this.attributes) {
        let key = this.attributes[k]

        if (key == "id") continue

        let selector = `#order_${this.type}_attributes_${key}`
        let el = document.querySelector(selector)
        if (el) el.value = null
      }

      let invoiceField = "receipt"
      Utils.simulateClick( document.querySelector(`#${invoiceField}`) )

      EventBus.$emit("checkout-address-selected", this.selectedAddress)
    }
  }
})
</script>
