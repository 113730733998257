<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { store } from '../lib/store'
import Utils from '../lib/utils'

export default {
  props: {
    productId: {
      required: true,
      type: String
    },
    option: {
      required: true,
      type: Object
    },
    sizes: {
      required: true,
      type: Array
    },
    selected: {
      required: true,
      type: Object
    }
  },
  store,
  data() {
    return {
      email: null,
      optionVariant: "",
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ]),
    selectedOptions() {
      let selectedOptions = this.selected
      selectedOptions[this.option.id] = this.optionVariant.id

      return selectedOptions
    },
    canRequestAvailability() {
      if (this.option.option_variants?.length) {
        return Boolean(this.optionVariant) && Boolean(Utils.validateEmail(this.email)) && !this.loading
      }
      else {
        return Boolean(Utils.validateEmail(this.email)) && !this.loading
      }
    }
  },
  methods: {
    /*
     * Creates an availability request
     */
    notifyMeWhenBackInStock() {
      if (this.loading) {
        return false
      }

      this.loading = true

      this.$store.dispatch('triggerEvent', 'gy::notify-me-when-back-in-stock-started')

      return this.$http.post(`${this.apiPath}/products/${this.productId}/notify_me_when_back_in_stock`, {
        email: this.email,
        options: this.selectedOptions
      }).then(response => {
        this.$store.dispatch('triggerEvent', {
          type: 'gy::notify-me-when-back-in-stock-completed',
          message: {
            message: response.body.message,
            errors: response.body.error
          }
        })

        this.restoreOptionVariant()
        this.loading = false
      }, response => {
        this.$store.dispatch('triggerEvent', 'gy::notify-me-when-back-in-stock-error')
        this.loading = false
      })
    },

    restoreOptionVariant() {
      this.optionVariant = ""
    }
  }
}
</script>
