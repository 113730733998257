<template>
  <div><slot /></div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import StoreFilter from './store_filter.vue'

export default Vue.component('store-filters', {
  props: {
    handle: {
      default: undefined
    },
    filters: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      storeTypes: []
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    filterChildren() {
      if (this.filters.length > 0) {
        for (let filter of this.filters) {
          if (this.handle == filter.handle) {
            return filter.children
          }
        }
      }
      return []
    }
  },
  methods: {
    resetSelected(elements) {
      for (let i=0; i++; i< elements.length) {
        elements[i].selected = false
      }
    }
  }
})
</script>
