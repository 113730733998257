<template>
  <img
    v-if="url"
    :src="url"
    :alt="alt"
    :width="width"
    :height="height"
  >
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    promotion: {
      default: () => {
        return {}
      },
      type: Object
    },
    position: {
      default: 1,
      type: Number
    },
    height: {
      default: '',
      type: String
    },
    width: {
      default: '',
      type: String
    },
    alt: {
      default: '',
      type: String
    }
  },
  computed: {
    url() {
      let url = ""

      if (this.promotion && this.promotion.images.length > 0 && this.promotion.images[this.position - 1] != undefined) {
        url = this.promotion.images[this.position - 1].url
      }

      return url
    }
  }
}
</script>
