<script>
import Vue                      from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus }             from '../../lib/event_bus.js'
import Moment                   from 'moment'
import { extendMoment }         from 'moment-range'

const moment = extendMoment(Moment)

export default {
  props: {
    day: {
      type: Array,
      default: () => {
        return []
      }
    },
    range: {
      type: Number,
      default: undefined
    },
    disabledDays: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      selected: false
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  methods: {
    /*
     * Returns ranges values
     */
    timeRange() {
      if (this.day[this.range - 1] == undefined) return

      let startTime = this.day[this.range - 1].start.format('HH:mm')
      let endTime = this.day[this.range - 1].end.format('HH:mm')

      return `${startTime}-${endTime}`
    },

    /*
     * Gets day from start of given range
     */
    dateFromDay() {
      let dayStart = new Date(this.day[0].start.year(), this.day[0].start.month(), this.day[0].start.date())

      return moment(dayStart)
    },

    /*
     * Indicates whether a range is disabled or not, based on exclusions & 24h minimium diff from current date
     */
    isDisabled() {
      let minTimeToDeliver = moment(this.$parent.currentDate).add(this.$parent.minHoursToDelivery, 'hour')
      let currentRange = this.day[this.range - 1]

      if (this.disabledDays.length > 0) {
        for (let disabledDay of this.disabledDays) {
          if (disabledDay == currentRange.end._d.getDay()) {
            return true
          }
        }
      }

      if (currentRange == undefined) return true

      for (let exclusion of this.$parent.builtExclusions) {
        if (currentRange.overlaps(exclusion) || minTimeToDeliver.within(currentRange) || minTimeToDeliver > currentRange.start) return true
      }

      return false
    },

    /*
     * Emits event to order delivery datetime component along with selected datetime
     */
    select() {
      this.$parent.deselectCells()
      this.selected = true
      EventBus.$emit('selectDatetime', { date: this.dateFromDay(), timeRange: this.timeRange() })
    }
  }
}
</script>
