<template>
  <div style="display: inline;">
    <MultiSelect
      :id="attrIdVal"
      ref="product_select2"
      label="title"
      track-by="id"
      :options="products"
      :placeholder="placeholderVal"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :close-on-select="true"
      :clear-on-select="true"
      :limit="1"
      :options-limit="optionsLimit"
      :limit-text="limitText"
      :custom-label="customLabel"
      select-label=""
      :selected-label="selectedLabel"
      :deselect-label="deselectedLabel"
      :reset-after="true"
      @search-change="findProduct"
      @select="select"
    >
      <span slot="noResult">
        {{ noResultVal }}
      </span>
      <span slot="noOptions">
        {{ noOptionsVal }}
      </span>

      <template
        slot="option"
        slot-scope="props"
      >
        <slot
          name="content"
          :option="props.option"
        />
      </template>
    </MultiSelect>
  </div>
</template>

<script>
/*
  Vue Multiselect
  GitHub: https://github.com/monterail/vue-multiselect
*/

import { mapState, mapGetters } from 'vuex'
import MultiSelect              from 'vue-multiselect'
import NiceI18n                 from '../../lib/nice_i18n'

export default {
  name: 'ProductSelect',
  components: {
    MultiSelect
  },
  props: {
    placeholder: {
      type: String,
      default: undefined
    },
    selectedProductId: {
      type: Number,
      default: undefined
    },
    attrId: {
      type: Number,
      default: undefined
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number,
      default: 3
    },
    per: {
      type: Number,
      default: 20
    },
    optionsLimit: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      products: [],
      productId: null,
      skuId: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'apiPath',
      'siteLocalePath'
    ]),

    attrIdVal() {
      return this.attrId
    },

    placeholderVal() {
      return typeof(this.placeholder) == 'undefined' ? NiceI18n.t('saved_orders.product_select.placeholder') : this.placeholder
    },

    noResultVal() {
      return NiceI18n.t('saved_orders.product_select.no_products_found')
    },

    noOptionsVal() {
      return NiceI18n.t('saved_orders.product_select.empty_list')
    },

    hasAutofocus() {
      return Boolean(this.autofocus)
    },

    selectedLabel() {
      return NiceI18n.t('saved_orders.product_select.selected_label')
    },

    deselectedLabel() {
      return NiceI18n.t('saved_orders.product_select.deselected_label')
    }
  },
  mounted() {
    if (this.selectedProductId) {
      this.productId = this.selectedProductId

      if (this.hasAutofocus) {
        this.$refs.product_select2.$refs.search.focus()
      }
    }
  },
  methods: {
    limitText (count) {
      return `${NiceI18n.t('saved_orders.product_select.limit_text.and')} ${count} ${NiceI18n.t('saved_orders.product_select.limit_text.other_products')}`
    },

    customLabel (option) {
      return `${option.code} : ${option.title}`
    },

    /*
    * on-select event
    * @param {string} option - the value of the selected option
    */
    select (option) {
      this.productId = option.product_id
      this.skuId     = option.sku_id

      this.$emit('gy:saved-line-item-product-changed', {
        productId: this.productId,
        skuId: this.skuId
      })
    },

    /*
     * It searches for products from API
     * @param {Object} query - the text user searches against
     */
    findProduct(query) {
      if (query && query.length >= this.minLength) {
        this.isLoading = true

        this.$http.get(`${this.siteLocalePath}/autocomplete/saved_order`,
          {
            params: {
              query: query,
              per: 20
            },

            // use before callback
            before(request) {
              // abort previous request, if exists
              if (this.previousRequest) {
                this.previousRequest.abort()
              }

              // set previous request on Vue instance
              this.previousRequest = request
            }
          }
        ).then(response => {
          this.products = response.body.products
          this.isLoading = false
        }, response => {
          // error callback
          this.products   = []
          this.isLoading  = false
        })
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
