<template>
  <a class="row search-result">
    <div class="col-sm-3 text-center">
      <div class="search-result__photo">
        <img
          :src="item.photo"
          alt=""
        >
      </div>
    </div>
    <div class="col-sm-9">
      <a :href="item.path">
        <p class="search-result__title">
          {{ item.title }}
        </p>
        <p class="search-result__code">
          <span v-if="withCodeLabel">
            {{ I18n.t("shared.header.search.product_code_label") }}
          </span>
          {{ item.code }}
        </p>
      </a>

      <p class="search-result-price" v-if="!hidePrice">
        <del
          v-if="item.price != item.discounted_price"
          class="search-result-price__original"
        >
          {{ item.price }}
        </del>
        <strong class="search-result-price__discounted">
          {{ item.discounted_price }}
        </strong>
      </p>
    </div>
  </a>
</template>

<script>
import { mapState } from 'vuex'
import NiceI18n from '../../lib/nice_i18n'

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    withCodeLabel: {
      type: Boolean,
      default: false,
      note: 'Configure to show or hide Code label before product code'
    }
  },
  data() {
    return {
      I18n: NiceI18n
    }
  },
  computed: {
    ...mapState([
      'hidePriceForProductGroupIds'
    ]),
    hidePrice() {
      if (this.item.product_group_id === undefined)
        return false

      return this.hidePriceForProductGroupIds.indexOf(this.item.product_group_id) > -1
    }
  }
}
</script>

<style scoped>
</style>
