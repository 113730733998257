<template>
  <div>
    <div
      v-show="couponActivated"
      class="decactivation-mode"
    >
      <span
        v-for="message in coupon.messages"
        :key="message"
        class="coupon-messages"
        v-html="message"
      />
      <a
        class="deactivate-coupon"
        href="#"
        @click.prevent="deactivateCoupon"
      >
        {{ deactivateMsg }}
      </a>
    </div>
    <div
      v-show="!couponActivated"
      class="coupon-content"
    >
      <div
        v-if="descriptionMsg"
        class="description-msg"
      >
        {{ descriptionMsg }}
      </div>
      <fieldset class="coupon-form">
        <input
          id="code"
          v-model="code"
          :placeholder="placeholder"
          label="false"
          name="code"
          type="text"
          @keyup.enter="activateCoupon"
        >
        <button
          class="button"
          name="button"
          type="submit"
          @click.prevent="activateCoupon"
        >
          <span>
            {{ applyButtonMsg }}
          </span>
        </button>
      </fieldset>
    </div>
    <div class="has-error">
      <Transition name="fade">
        <span v-if="errors.coupon">
          {{ errors.coupon }}
        </span>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    couponType: {
      type: String,
      default: undefined
    },
    deactivateMsg: {
      type: String,
      default: ''
    },
    applyButtonMsg: {
      type: String,
      default: 'Submit'
    },
    descriptionMsg: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      code: null,
      thirdPartyCouponLoading: false,
      errors: {
        coupon: null
      }
    }
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    couponActivated() {
      return this.coupon && this.coupon.code && this.coupon.code.length > 0
    }
  },
  methods: {
    /*
     * It activates the coupon.
     * @event - gy::coupon-activated event is triggered when activated
     * @event - gy::coupon-invalid event is triggered when is invalid
     */
    activateCoupon() {
      if (this.couponType) this.toggleThirdPartyCouponLoader()

      return this.$http.post(`${this.apiPath}/coupons/activate`, { code: this.code, coupon_type: this.couponType }).then(response => {
        this.$store.dispatch('loadOrder')
        this.errors.coupon = null
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-activated', message: response.body.message })

        if (this.couponType) this.toggleThirdPartyCouponLoader()
      }, response => {
        this.errors.coupon = null

        setTimeout( () =>
          this.errors.coupon = response.body.message
        , 80)

        if (this.couponType) this.toggleThirdPartyCouponLoader()
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-invalid', message: response.body.message })
      })
    },

    /*
     * It deactivates the coupon.
     * @event - gy::coupon-deactivated event is triggered when activated
     */
    deactivateCoupon() {
      return this.$http.post(`${this.apiPath}/coupons/deactivate`).then(response => {
        this.$store.dispatch('loadOrder')
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-deactivated', message: response.body.message })
      })
    },

    /*
     * It toggles third party coupon loader.
     */
    toggleThirdPartyCouponLoader() {
      this.thirdPartyCouponLoading = !this.thirdPartyCouponLoading
    }
  }
}
</script>
