<template>
  <div style="display: inline;">
    <MultiSelect
      :value="address"
      :options="options"
      label="title"
      track-by="id"
      :selected-label="selectedLabel"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :placeholder="placeholderVal"
      :close-on-select="true"
      :clear-on-select="true"
      @select="select"
      @remove="remove"
    />
    <input
      v-model="address.id"
      type="hidden"
      :name="inputName"
    >
    <span
      v-if="$v.address.id.$error"
      class="error"
    >
      {{ error_message }}
    </span>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import MultiSelect            from '../../lib/vue-multiselect'
import Utils                  from '../../lib/utils'
import NiceI18n               from '../../lib/nice_i18n'
import AjaxState              from '../../lib/ajax_state'
import { validationMixin }    from 'vuelidate'
import { required, numeric }  from 'vuelidate/lib/validators'

export default Vue.component('b2b-address-select', {
  components: {
    MultiSelect
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: "Please select address"
    },
    addressId: {
      type: String,
      default: undefined
    },
    selectedLabel: {
      type: String,
      default: ""
    },
    selectLabel: {
      type: String,
      default: ""
    },
    deselectLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error_message: NiceI18n.t("activerecord.errors.models.address.attributes.country.blank"),
      address: {}
    }
  },
  computed: {
    ...mapState([
      'addresses'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    inputName() {
      return `order[b2b_${this.type}_id]`
    },
    placeholderVal() {
      return this.placeholder
    },
    billingAddress() {
      return this.type == "billing_address"
    },
    options() {
      return this.addresses.filter(address => this.billingAddress ? address.type == "B2b::BillingAddress" : address.type == "B2b::ShippingAddress")
    }
  },
  watch: {
    addresses: {
      handler(newValue, oldValue) {
        this.preselectAddress()
      },
      deep: true
    }
  },
  mounted() {
    this.loadAddresses()
  },
  validations: {
    address: {
      id: {
        required,
        numeric
      }
    }
  },
  methods: {
    select(option) {
      this.address = option

      this.$store.commit("setAddress", { type: this.type, address: option })
    },
    remove() {
      this.address = {}
    },
    loadAddresses() {
      let state = AjaxState.initialize('loadAddresses')
      if (state.fired) return

      state.fire()

      this.$http.get(`${this.apiPath}/addresses`).then(response => {
        this.$store.commit('setAddresses', response.body.addresses)
        this.$store.dispatch('triggerEvent', 'gy::addresses-loaded')

        state.clear()
      })
    },
    preselectAddress() {
      if (this.addressId == undefined) return

      for (let address of this.addresses) {
        if (address.id == parseInt(this.addressId)) {
          this.$store.commit('setAddress', { type: this.type, address: address })
          this.address = address
        }
      }
    }
  }
})
</script>
