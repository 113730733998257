<script>
import { sumBy, uniq } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import * as api from '../../api/api'
import checkout from '../../apps/checkout'
import Coupon from '../../components/coupon.vue'
import Product from '../../components/product.vue'

export default {
  components: {
    Coupon,
    Product
  },
  props: {
    items: {
      default: () => {
        return []
      },
      type: Array
    },
    itemKey: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      optionVariantId: null
    }
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    item() {
      return this.items[0]
    },
    quantity() {
      return sumBy(this.items, 'quantity')
    },
    selectedOptions() {
      const idx = Object.values(this.item.options).indexOf(parseInt(this.optionVariantId))

      if (idx < 0) {
        return this.item.selected_options
      } else {
        const key = Object.keys(this.item.selected_options)[idx]
        let selectedOptions = {}

        selectedOptions[key] = this.item.selected_options[key]

        return selectedOptions
      }
    },
    totalPrice() {
      return sumBy(this.items, (item) => {
        return Number(item.prices.total_price)
      })
    },
    totalDiscountedPrice() {
      return sumBy(this.items, (item) => {
        return Number(item.prices.total_discounted_price)
      })
    },
    totalTaxCost() {
      return sumBy(this.items, (item) => {
        return Number(item.prices.total_tax_cost)
      })
    },
    totalDiscount() {
      return this.totalPrice - this.totalDiscountedPrice
    },
    restrictableErrors() {
      return uniq(this.items.map((item, i) => (item.restrictable_errors)).flat())
    }
  },
  mounted() {
    const [productId, optionVariantId] = this.itemKey.split(",")
    this.optionVariantId = optionVariantId
  },
  methods: {
    deleteLineItem() {
      let requests = []

      for (let item of this.items) {
        requests.push(
          api.deleteLineItem(this.apiPath, item.id)
        )
      }

      axios
        .all(requests)
        .then(axios.spread((...responses) => {
          let response = responses[responses.length - 1]

          this.$store.dispatch('loadOrder')

          checkout.refreshPaymentMethods()
          checkout.refreshShippingMethods()

          this.$store.dispatch('triggerEvent', { type: 'gy::line-item-deleted', message: response.data.message })
        }))
    }
  }
}
</script>