<template>
  <div class="gdpr-cp-option">
    <PCheck
      v-model="field.agree"
      class="p-icon p-curve p-pulse p-bigger"
      name="check"
      color="success"
      @change="updateConsent()"
    >
      <i
        slot="extra"
        class="icon mdi mdi-check"
      />
      <span>{{ field.title }}</span>
    </PCheck>
  </div>
</template>

<script>
export default {
  props: ["field"],
  methods: {
    updateConsent() {
      this.setDomainAndToken()

      this.$http.patch("consent_preferences", {

        consent: this.field,
        domain: this.domain,
        locale: window.locale || this.locale
      },
      { headers: {
        "X-Authorization-Token": this.token
      } }
      )
    },
    setDomainAndToken() {
      if (window.gy_moad != undefined) {
        this.domain = window.gy_moad.domain
        this.token  = window.gy_moad.token

        if (window.gy_moad.locale != undefined) {
          this.locale = window.gy_moad.locale
        } else {
          this.locale = "el"
        }
      } else {
        this.domain = window.location.hostname
        this.token  = ''
        this.locale = "el"
      }
    }
  }
}
</script>