import Vue              from 'vue'
import VueResource      from 'vue-resource'
import { mapGetters }   from 'vuex'
import { store }        from '../lib/store'
import savedOrdersMixin from '../mixins/saved_orders_mixin'
import SavedOrder       from '../components/saved_orders/SavedOrder.vue'
import SavedLineItem    from '../components/saved_orders/SavedLineItem.vue'

Vue.use(VueResource)

export default new Vue({
  el: '#gy-saved-order-edit',
  components: {
    SavedOrder,
    SavedLineItem
  },
  mixins: [savedOrdersMixin],
  data: {
    savedOrder: {}
  },
  store,
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    if (this.$el.attributes.savedOrderId) {
      this.id = this.$el.attributes.savedOrderId.value
      this.loadSavedOrder()
    }
  },
  methods: {
    /*
     * Loads the saved orders from the API. Its called once when app is mounted.
     */
    loadSavedOrder() {
      this.$http.get(`${this.apiPath}/saved_orders/${this.id}/edit`).then(response => {
        var order = response.body.saved_order
        this.handleCustomAttributes(order)
        this.savedOrder = order
        this.$store.dispatch('triggerEvent', 'gy::saved-order-loaded')
      })
    }
  }
})
