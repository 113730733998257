import Vue              from 'vue'
import VueResource      from 'vue-resource'
import { mapGetters }   from 'vuex'
import { store }        from '../lib/store'
import savedOrdersMixin from '../mixins/saved_orders_mixin'
import SavedOrder       from '../components/saved_orders/SavedOrder.vue'
import SavedLineItem    from '../components/saved_orders/SavedLineItem.vue'

Vue.use(VueResource)

export default new Vue({
  el: '#gy-saved-order-new',
  components: {
    SavedOrder,
    SavedLineItem
  },
  mixins: [savedOrdersMixin],
  data: {
    savedOrder: {},
    savedOrders: []
  },
  store,
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    if (this.$el.id && this.$el.id.length > 0) {
      this.initSavedOrder()
      this.loadSavedOrders()
    }
  },
  methods: {
    /*
     * Inits a non-persisted saved order from API
     */
    initSavedOrder() {
      this.$http.get(`${this.apiPath}/saved_orders/new`).then(response => {
        var order = response.body.saved_order
        this.handleCustomAttributes(order)
        this.savedOrder = order
        this.savedOrder.isQuickOrder = true
      })
    },

    /*
     * Callback event on after save for an quick order
     */
    afterQuickOrderSave() {
      this.loadSavedOrders()
    }
  }
})
