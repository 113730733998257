<script>
import { mapState, mapGetters } from 'vuex'
import Addresses                from '../addresses.vue'
import niceModal                from '../../mixins/nice_modal'
import { EventBus }             from '../../lib/event_bus.js'

import { required, minLength, minValue, email, numeric } from 'vuelidate/lib/validators'
const queryString = require('query-string')

export default {
  components: {
    'addresses': Addresses
  },
  mixins: [niceModal],
  data() {
    return {
      giftlist: {}
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    isNewRecord() {
      let newGiftList = true

      if (this.giftlist.hasOwnProperty('id')) {
        if (this.giftlist.id) {
          newGiftList = false
        }
      }

      return newGiftList
    }
  },
  validations: {
    giftlist: {
      title: {
        required,
        minLength: minLength(2)
      },
      date_of_event: {
        required
      },
      address_id: {
        required
      }
    }
  },
  mounted() {
    EventBus.$on('populate-giftlist-form', this.setGiftlist)
    EventBus.$on('set-address-to-giftlist', this.setAddressToGiftList)
  },
  methods: {
    /*
     * Submits gift list from. Creates and edits a gift list
     */
    submit() {
      if (this.giftlist.id) {
        this.$http.put(`${this.apiPath}/gift_lists/${this.giftlist.id}`, { giftlist: this.giftlist }).then(response => {
          EventBus.$emit("reload-gift-lists")
          this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-updated', message: response.body.message })
        },
        (error) => {
          this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-updated', message: error.body.message })
        })
          .catch((error) => {})
      } else {
        this.$http.post(`${this.apiPath}/gift_lists`, { giftlist: this.giftlist }).then(response => {
          EventBus.$emit("reload-gift-lists")
          EventBus.$emit("select-option", { id: parseInt(response.body.gift_list_id) })
          this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-created', message: response.body.message })
          window.location.href = location.protocol + '//' + location.host + "/gift_lists/" + response.body.gift_list_id + "/edit";
        },
        (error) => {
          this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-not-created', message: error.body.message })
        })
          .catch((error) => {})
      }
    },

    /*
     * Opens gift list popup with an address prepopulated
     * @param {integer} address_id - id of address
     */
    setAddressToGiftList(address_id) {
      this.giftlist.address_id = address_id
      this.$store.dispatch('triggerEvent', { type: 'gy::giftlist-form-populated', message: "" })
    },

    /*
     * Initializes gift list
     * @param {object} giftlist
     */
    setGiftlist(giftlist) {
      this.giftlist = giftlist
    },

    addValidateClass(element) {
      return { error: element.$error, valid: element.$dirty && !element.$invalid }
    },

    disableButton() {
      return this.$v.$invalid
    }
  }
}
</script>
