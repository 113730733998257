import { store } from '../../lib/store'
import NiceI18n from '../../lib/nice_i18n'
import Utils from '../../lib/utils'
import axios from 'axios'
export default class Simplify {
  constructor(clientId, transactionUrl, order) {
    this.clientId = clientId
    this.transactionUrl = transactionUrl
    this.order = order
  }

  generateToken(data) {
    // Remove empty spaces from card number
    let number = data.cardNumberNotMask.replace(/\s/g, "")
    let $this  = this
    let times  = 1

    let responseHandler = (data, status) => {
      if ((status == 405 || status == 403) && times > 0) {
        callSimplify(false)
        times--
        return
      }

      if (data.id !== undefined && data.card !== undefined) {
        $this.token = data.id
        $this.card  = data.card

        console.log(`Token: ${$this.token}`)
        console.debug($this.card)

        store.commit('setSimplify', $this)

        if (!$this.secure3DEnabled) {
          $this.generateTransaction()
        }
      }
      else {
        $this.error = data.error

        $this.emmitErrors()
      }
    }

    let amount = parseFloat(this.order.final_cost) * 100
    let zipCode = this.order.billing_address.zip.replace(/\s/g, "")

    let callSimplify = (use3DSecure = true) => {
      let options = {
        key: $this.clientId,
        card: {
          name: data.cardName,
          number: number,
          cvc: data.cardCvv,
          expMonth: data.cardMonth,
          expYear: String(data.cardYear).slice(-2),
          addressCity: $this.order.billing_address.city,
          addressLine1: [$this.order.billing_address.street, $this.order.billing_address.street_number].join(' ').trim(),
          addressZip: zipCode,
          addressCountry: $this.order.billing_address.country.code
        }
      }

      if (use3DSecure) {
        options.secure3DRequestData = {
          amount: amount,
          currency: $this.order.currency_code,
          description: `Order #${$this.order.code}`
        }
      }

      SimplifyCommerce.generateToken(options, responseHandler)
    }

    callSimplify()
  }

  generateTransaction() {
    store.commit('setCreditCard', { errors: {} })

    axios.post(this.transactionUrl, {
      payment_id: this.token
    }).then(response => {
      store.commit('setCreditCardLoading', false)
      store.commit('setCreditCardResponse', {
        success: response.data.success,
        status: response.data.status
      })

      if (response.data.success) {
        setTimeout(() => {
          window.location.href = response.data.url
        }, 1500)
      } else {
        // Set timeout to 6 seconds to display again the pay button
        setTimeout(() => {
          store.commit('setCreditCardResponse', {})
        }, 6000)
      }
    })
  }

  emmitErrors() {
    let errors = {}

    if (this.error && this.error.code) {
      errors['genericError'] = NiceI18n.t('pay_modal.errors.card.genericError')
      Utils.logNotice(`Simplify Error Message`, "Rothschild", this.error.message)
    }

    if (this.error.fieldErrors) {
      Utils.logNotice(`Simplify Payment Form Errors`, "Rothschild", this.error.fieldErrors)

      for (let error of this.error.fieldErrors) {
        let field = this.errorMapping[error.field]

        if (field) {
          let key = `pay_modal.errors.card.${field}.${error.code}`
          let message = NiceI18n.t(key, { defaultValue: error.message })
          errors[field] = message || error.message
        }
      }
    }

    store.commit('setCreditCard', { errors: errors })
    store.commit('setCreditCardLoading', false)
  }

  get secure3DEnabled() {
    return this.card && this.card.secure3DData !== undefined && this.card.secure3DData.isEnrolled
  }

  get errorMapping() {
    return {
      "card.number": "cardNumber",
      "card.name": "cardName",
      "card.expMonth": "cardMonth",
      "card.expYear": "cardYear",
      "card.cvc": "cardCvv"
    }
  }
}
