<template>
  <div>
    <modals-container />
    <a
      href="#"
      class="gy-pay-button"
      :class="buttonClass"
      @click.prevent="openCreditCardModal"
    > {{ buttonValue }} </a>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import CreditCardModal from './CreditCardModal.vue'
import Everypay from '../../classes/rothschild/everypay'

export default {
  props: {
    identifier: {
      type: String
    },
    type: {
      type: String
    },
    sandbox: {
      default: 'false',
      type: String
    },
    clientId: {
      type: String
    },
    transactionUrl: {
      type: String
    },
    order: {
      type: Object
    },
    defaultCardName: {
      default: 'NAME',
      type: String
    },
    buttonClass: {
      default: '',
      type: String
    },
    buttonValue: {
      default: '',
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isSimplify() {
      return this.type == 'simplify'
    },
    isEverypay() {
      return this.type == 'everypay'
    }
  },
  mounted() {
    switch (this.type) {
    case 'simplify':
      let element  = document.createElement('script')
      const source = 'https://www.simplify.com/commerce/v1/simplify.js'

      element.setAttribute('src', source)
      document.head.appendChild(element)

      break
    case 'everypay':
      if (document.getElementById('everypay-script') === null) {
        let element  = document.createElement('script')
        const source = this.sandbox === 'true' ? 'https://sandbox-js.everypay.gr/v3' : 'https://js.everypay.gr/v3'

        element.setAttribute('id', 'everypay-script')
        element.setAttribute('src', source)
        document.head.appendChild(element)
      }

      break
    }

    this.$store.subscribeAction((action, state) => {
      if (action.type !== 'openCreditCardModal')
        return

      this.openCreditCardModal()
    })
  },
  methods: {
    openCreditCardModal: debounce(function() {
      this.$modal.show(CreditCardModal, {
        clientId: this.clientId,
        type: this.type,
        transactionUrl: this.transactionUrl,
        defaultCardName: this.defaultCardName,
        order: this.order
      }, {
        height: 'auto',
        width: 580,
        adaptive: true,
        scrollable: true,
        clickToClose: false
      })

      if (this.isEverypay) {
        let provider = new Everypay(this.clientId, this.transactionUrl, this.order)
        provider.generateToken()
      }
    }, 1)
  }
}
</script>