<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('store-detail', {
  props: {
    store: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  mounted() {
    EventBus.$on('store-selected', this.show)
  },
  methods: {
    show() {
      this.visible = true
    },

    hide() {
      this.visible = false
    },

    selectStore(store) {
      EventBus.$emit('selected-store-set', store)
    }
  }
})
</script>