<script>
require('es6-promise/auto')

import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import miniCart from '../apps/mini_cart'
import MixNMatchItem from '../components/mix_n_match_item.vue'

export default Vue.component('mix-n-match', {
  props: ['handle'],
  data() {
    return {
      addedToCart: false,
      errors: [],
      products: []
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),

    hasErrors() {
      return this.errors.length > 0
    }
  },
  mounted() {
  },
  methods: {
    /*
     * Adds mix'n'match to cart
     * @event - gy::mix-n-match-added-to-cart event is triggered
     */
    addToCart() {
      this.setSelectedProducts()
      this.errors = []
      let $this   = this

      let $promise = new Promise( (resolve, reject) => {
        let products = []
        let promises = []

        // Loop all elements and call loadOptions using promise.
        for (let element of this.products) {
          if (element.options.length == 0) {
            promises.push(new Promise(resolve => { resolve(element.loadOptions()) }))
          }
        }

        // Check for all products of bundle if user has selected color/size etc. If yes, continue.
        Promise.all(promises).then(response => {
          for (let element of this.products) {
            if (element.optionsValidated()) {
              let product_id = element.product ? element.product.id : Number(element.productId)

              products.push({ product_id: product_id, options: element.selected, quantity: element.quantity })
            } else {
              this.errors.push(element.errors)
            }
          }

          if (this.errors.length == 0) {
            resolve(products)
          } else {
            $this.clickedWhenDisabled = true
            let errorMessages = []

            for (let key of Object.keys(this.errors)) {
              var option = this.errors[key]
              errorMessages.push(option.title)
            }

            setTimeout(() => {
              $this.clickedWhenDisabled = false
            }, 800)

            this.$store.dispatch('triggerEvent', { type: 'gy::mix-n-match-cannot-add-to-cart', message: errorMessages.join(', ') })
            reject(this.errors)
          }
        })
      }).then(products => {

        this.$http.post(`${$this.apiPath}/order`, {
          products: products
        }).then(response => {
          this.addedToCart = true
          this.$store.dispatch('loadOrder')

          this.$store.dispatch('triggerEvent', { type: 'gy::mix-n-match-added-to-cart', message: response.body.message })

          setTimeout(() => {
            $this.addedToCart = false
          }, 4200)
        })
      })
    },

    setSelectedProducts() {
      this.products = []

      for (let child of this.$children) {
        for (let product of child.$refs.products) {
          if (product.product.selected)
            this.products.push(product)
        }
      }
    }
  },
  components: {
    MixNMatchItem
  }
})
</script>
