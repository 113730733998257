<script>
import Vue                       from 'vue'
import { mapState, mapGetters }  from 'vuex'
import { store }                 from '../../lib/store'
import { EventBus }              from '../../lib/event_bus.js'
import Moment                    from 'moment'
import { extendMoment }          from 'moment-range'
import OrderDeliveryDatetimeCell from './OrderDeliveryDatetimeCell.vue'

const moment = extendMoment(Moment)

export default {
  components: {
    OrderDeliveryDatetimeCell
  },
  props: {
    deliveryDate: {
      type: String,
      default: undefined
    },
    deliveryTime: {
      type: String,
      default: undefined
    },
    instantDelivery: {
      type: String,
      default: undefined
    },
    weeksDisplayed: {
      type: String,
      default: undefined
    },
    instantDeliveryTime: {
      type: String,
      default: undefined
    },
    minHoursToDelivery: {
      type: String,
      default: undefined
    },
    canInstantlyDeliver: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      locale: window.locale || 'el',
      canDeliverToday: typeof(this.canInstantlyDeliver) == 'undefined' ? false : true,
      deliverToday: typeof(this.canInstantlyDeliver) == 'undefined' ? false : true,
      submittedDate: moment(),
      submittedRange: moment().add(this.instantDeliveryTime, 'minute').format('HH:mm'),
      currentDate: moment(),
      ranges: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
      builtRanges: [],
      exclusions: [],
      builtExclusions: [],
      currentWeek: 0,
      checkedSubmittedRange: false
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    '$store.state.order': function(updatedOrder, order) {
      if (Object.entries(order).length > 0 && updatedOrder != order) {
        this.canDeliverToday = updatedOrder.can_instantly_deliver && updatedOrder.instantly_serviceable

        if (!this.canDeliverToday) {
          this.deliverToday = !this.deliverToday
          this.unsetInstantDelivery()
        }
      }
    }
  },
  mounted() {
    this.loadDeliveryTimeRanges()
    this.loadDeliveryTimeExclusions()

    if (!this.canDeliverToday && this.instantDelivery != undefined) this.unsetInstantDelivery()

    EventBus.$on('selectDatetime', (payload) => {
      this.setSubmittedDateTime(payload.date, payload.timeRange)
    })
  },
  updated() {
    if (this.shouldPreselectRange()) {
      this.preselectSubmittedRange()
    }
  },
  methods: {
    /*
     * Fetches delivery time ranges from API
     */
    loadDeliveryTimeRanges() {
      this.$http.get(`${this.apiPath}/delivery_time/ranges`).then(response => {
        for (let range of response.body.delivery_time_ranges) {
          this.ranges[range.day_of_week].push({ from: range.from, to: range.to })
        }

        this.createRanges()
      })
    },

    /*
     * Fetches delivery time exclusions from API
     */
    loadDeliveryTimeExclusions() {
      this.$http.get(`${this.apiPath}/delivery_time/exclusions`).then(response => {
        for (let exclusion of response.body.delivery_time_exclusions) {
          this.exclusions.push({ from: new Date(exclusion.from), to: new Date(exclusion.to) })
        }

        this.createExlusions()
      })
    },

    /*
     * Creates ranges object
     */
    createRanges() {
      let n = 1

      while (n <= 21) {
        let nextDay = moment(this.currentDate).add(n, 'day').startOf('day')

        let ranges = nextDay.day() == 0 ? this.ranges[6] : this.ranges[nextDay.day() - 1]

        let dayRanges = []

        for (let range of ranges) {
          let from = moment(nextDay).add(range.from, 'second')
          let to = moment(nextDay).add(range.to, 'second')

          dayRanges.push(moment().range(from , to))
        }


        this.builtRanges.push(dayRanges)

        n++
      }
    },

    /*
     * Creates exclusions object
     */
    createExlusions() {
      for (let exclusion of this.exclusions) {
        let from = moment(exclusion.from)
        let to = moment(exclusion.to)

        this.builtExclusions.push(moment().range(from, to))
      }
    },

    /*
     * Finds & checks delivery time element preselects it
     * if order delivery date & time is already submitted
     */
    preselectSubmittedRange() {
      for (let cell of this.$children) {
        let cellDate = cell.dateFromDay().format('YYYY-MM-DD')
        let cellTime = cell.timeRange()

        if (cellDate == this.deliveryDate && cellTime == this.deliveryTime) {
          cell.selected = true
          this.submittedDate = moment(this.deliveryDate)
          this.submittedRange = this.deliveryTime
          this.deliverToday = false
          this.checkedSubmittedRange = true
          return
        }
      }

      if (this.lastWeekDisplayed()) {
        this.resetWeek()
        this.checkedSubmittedRange = true
      }
      else {
        this.nextWeek()
      }
    },

    shouldPreselectRange() {
      return !this.checkedSubmittedRange && this.deliveryDate != undefined && this.deliveryDate != undefined && this.instantDelivery == undefined
    },

    /*
     * Returns days & ranges of selected week
     */
    week() {
      return this.builtRanges.slice(this.currentWeek * 7, (this.currentWeek + 1) * 7)
    },

    /*
     * Returns max number of ranges per day in order to build ranges table
     */
    maxDayRangesNumber() {
      let max = 0


      for (let day of this.week()) {
        if (day.length > max) max = day.length
      }


      return max
    },

    deselectCells() {
      for (let cell of this.$children) {
        if (cell.selected) {
          cell.selected = !cell.selected
          return
        }
      }
    },

    resetWeek() {
      this.currentWeek = 0
    },

    nextWeek() {
      if (this.currentWeek + 1 == this.weeksDisplayed) return

      this.deselectCells()
      this.setInstantDelivery()
      this.currentWeek++
    },

    previousWeek() {
      if (this.currentWeek == 0) return

      this.deselectCells()
      this.setInstantDelivery()
      this.currentWeek--
    },

    setSubmittedDateTime(date, range) {
      this.submittedDate = date
      this.submittedRange = range
    },

    setInstantDelivery() {
      this.submittedDate = moment()
      this.submittedRange = moment().add(this.instantDeliveryTime, 'minute').format('HH:mm')
    },

    unsetInstantDelivery() {
      this.submittedDate = null
      this.submittedRange = null
    },

    /*
     * Prints out selected delivery date according to format arg passed
     */
    selectedDate(date, format) {
      if (date == undefined) return

      return moment(date).format(format)
    },

    /*
     * Formats dates shown on calendar according to arg passed
     */
    formattedDateFrom(range, format) {
      let day = range[0].start

      return day.locale(this.locale).format(format)
    },

    firstWeekDisplayed() {
      return this.currentWeek == 0
    },

    lastWeekDisplayed() {
      return this.currentWeek == this.weeksDisplayed - 1
    }
  }
}
</script>
