<template>
  <div>
    <multi-select
      v-model="value"
      deselect-label="Can't remove this value"
      track-by="title"
      label="title"
      placeholder="Select one"
      :options="options"
      :searchable="false"
      :allow-empty="false"
      :close-on-select=true
      :loading="isLoading">
    </multi-select>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'

export default Vue.component('mix-n-match-taxonomy-select', {
  props: ['options'],
  data() {
    return {
      value: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState([
    ]),
    ...mapGetters([
      'apiPath',
      'siteLocalePath'
    ]),

    hasErrors() {
      return this.errors.length > 0
    }
  },
  watch: {
    value: function(newTaxonomy, oldTaxonomy) {
      if (oldTaxonomy != newTaxonomy && oldTaxonomy != undefined) {
        this.$emit('taxonomy-changed', newTaxonomy)
      }
    },
    options: function(newOptions, oldOptions) {
      if (oldOptions.length == 0) {
        this.value = this.options[0]
      }
    }
  },
  mounted() {
    this.value = this.options[0]
  },
  methods: {
  },
  components: {
    MultiSelect
  }
})
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
